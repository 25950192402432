.b-container {
	max-width: $container-max-width;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: $column-gap;
	@media screen and (max-width: $responsive-start-bp) {
		padding-left: 54px;
		padding-right: 54px;
	}
	&--bottom {
		@media screen and (max-width: $mobile-bp) {
			padding-top: 54px;
		}
	}
}

.b-wrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: $column-gap;
	@media screen and (max-width: $mobile-bp) {
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 0;
	}
}

.b-col {
	@media screen and (max-width: $responsive-start-bp) {
		width: calc(50% - 27px);
	}
	@media screen and (max-width: $mobile-bp) {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $column-gap;
	}
	&--centered {
		display: flex;
		justify-content: center;
		width: 100%;
	}
}
