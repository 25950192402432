* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: sans-serif;
    height: 100%;
}

body {
    font-size: 16px;
    overflow-x: hidden;
}

h4 {
    font-size: 1em;
    font-weight: 400;
}