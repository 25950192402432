ul {
    list-style-type: none;
}

li {
    display: block;
}

a {
    text-decoration: none;
}