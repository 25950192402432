// fonts
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i');

// sizes
$container-max-width: 1134px;
$column-gap: 54px;
$header-height: 200px;
$container-padding: $header-height + $column-gap;

// colors
$button-color: #000000;

// fonts
$button-font: 'Playfair Display', serif;

// breakpoints
$responsive-start-bp: 1242px;
$nav-hidden-bp: 1000px;
$mobile-bp: 760px;