.ss18 {
	font-size: 16px;
	.bottom-wrap {
    color: inherit;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 3.75em;
    margin-bottom: 15.5em;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
		img {
			max-width: 18em;
			height: auto;
		}
		h4 {
			margin-top: 1rem;
			font-size: 1.25em;
			text-transform: uppercase;
      letter-spacing: .5px;
      color: inherit;
		}
	}
}