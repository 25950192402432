.button {
	display: inline-flex;
	// border: 1px solid #E9E8E9;
	font-family: $button-font;
	font-size: 1em;
	color: $button-color;
	font-style: italic;
	font-weight: normal;
	padding: 0 1.5em;
	height: 50px;
	align-items: center;
	&--small {
		align-items: center;
		font-size: 0.875em;
		padding: 0.4em 0.75em;
	}
	&--icon {
		padding: 0;
		border: unset;
		font-family: sans-serif;
		font-style: normal;
		font-weight: 400;
		align-items: center;
		svg,
		img {
			margin-left: 0.5em;
			transform: translateX(0);
			transition: ease 0.32s transform;
		}
		&:hover {
			svg,
			img {
				transform: translate(0.5em);
			}
		}
	}
}
