.b-cover {
	position: relative;
	width: 540px;
	height: 700px;
	transform-origin: center;
	transition: 0.1s linear transform;
	// transition-delay: .2s;
	@media screen and (max-width: $responsive-start-bp) {
		width: 100%;
		height: auto;
	}
	@media screen and (max-width: $mobile-bp) {
		// transition-delay: 0.4s;
	}
	img,
	video,
	picture {
		display: block;
	}
	&-front {
		font-size: 0;
		.video-wrapper {
			font-size: 0;
		}
		img,
		video {
			width: 100%;
			height: auto;
		}
	}
	&-overlay {
		user-select: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		opacity: 0;
		transition: 0.5s ease-out opacity;
		// transition-delay: 0.2s;

		@media screen and (max-width: $mobile-bp) {
			// transition-delay: 0.4s;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&.active {
		.b-cover-overlay {
			opacity: 1;
		}
	}

	&[data-scroll='in'] {
		// transform: scale(1.022);
		.b-cover-overlay {
			opacity: 1;
		}
	}
	&[data-scroll='out'] {
		// transform: scale(1);
		.b-cover-overlay {
			opacity: 0;
		}
	}
}
